export default {
    RUMAH: 'RUMAH',
    KERJA: 'KERJA',
    HP: 'HP',
    HP_PRIMARY: 'HP_PRIMARY',
    LAINNYA: 'LAINNYA',
    TIME: 'DT-1',
    TIME_EN: 'DT-2',
    LOWER_CASE: 'LC-1',
    UPPER_CASE: 'UC-1',
    CAPITALIZE_CASE: 'CC-1',
    CAPITALIEZE_CASE_ALL: 'CC-2',
    FORMAT_WHATSAPP_TEMPLATE: {
        'DT-1': (data) => {
            try {
                moment.locale('id')
                return moment(data).format('DD MMMM YYYY')
            } catch {
                return data
                // error
            }  
        },
        'DT-2': (data) => {
            try {
                moment.locale('en')
                return moment(data).format('DD MMMM YYYY')
            } catch {
                return data
                // error
            }
        },
        'LC-1': (data) => {
            try {
                return data.toLowerCase()
            } catch {
                return data
            }
        },
        'UC-1': (data) => {
            try {
                return data.toUpperCase()
            } catch {
                return data
            }
        },
        'CC-1': (data) => {
            try {
                const lower = data.toLowerCase()
                return data.charAt(0).toUpperCase() + lower.slice(1)
            } catch {
                return data
            }
        },
        'CC-2': (data) => {
            try {
                return data
                    .toLowerCase()
                    .split(' ')
                    .map(element => element.charAt(0).toUpperCase() + element.slice(1))
                    .join(' ');
            } catch {
                return data
            }
        },
        'DAY-1': (data) => {
            // day  EN
            try {
                moment.locale('en')
                return moment(data).format('dddd')
            } catch {
                return data
            }
        },  
        'DAY-2': (data) => {
            try {
                moment.locale('id')
                return moment(data).format('dddd')
            } catch {
                return data
            }
        },
        DA01I: (data) => {
            // 31 Agu 2024
            try {
                moment.locale('id')
                return moment(data).format('DD MMM YYYY')
            } catch {
                return data
            }
        }, 
        DA01E: (data) => {
            try {
                // 31 Aug 2024
                moment.locale('en')
                return moment(data).format('DD MMM YYYY')
            } catch {
                return data
            }
        }, 
        DA02I: (data) => {
            // 31 Agustus 2024
            try {
                moment.locale('id')
                return moment(data).format('DD MMMM YYYY')
            } catch {
                return data
            }
        },  
        DA02E: (data) => {
            // 31 August 2024
            try{
                moment.locale('en')
                return moment(data).format('DD MMMM YYYY')
            } catch {
                return data
            }
        },  
        DA03I: (data) => {
            // Sab, 31 Agu 2024
            try {
                moment.locale('id')
                return moment(data).format('ddd, DD MMMM YYYY')
            } catch {
                return data
            }
        }, 
        DA03E: (data) => {
            // Sat, 31 Aug 2024
            try {
                moment.locale('en')
                return moment(data).format('ddd, DD MMMM YYYY')
            } catch {
                return data
            }
        }, 
        DA04I: (data) => {
            // Sabtu, 31 Agustus 2024
            try {
                 moment.locale('id')
                return moment(data).format('dddd, DD MMMM YYYY')
            } catch {
                return data
            }
        },
        DA04E: (data) => { 
            // Saturday, 31 August 2024
            try {
                 moment.locale('en')
                return moment(data).format('dddd, DD MMMM YYYY')
            } catch {
                return data
            }
        }, 
        TI01X: (data) => {
            // 23:45
            try {
                moment.locale('id')
                return moment(data).format('HH:mm')
            } catch {
                return data
            }
        }, 
        TI02X: (data) => {
            // 23:45:01
            try {
                moment.locale('id')
                return moment(data).format('HH:mm:ss')
            } catch {
                return data
            }
        }, 
        TI03X: (data) => {
            // 11:45 PM
            try {
                moment.locale('en')
                return moment(data).format('HH:mm A')
            } catch {
                return data
            }
        }, 
        TE01X: (data) => {
            try {
                // lowercase
                return data.toUpperCase()
            } catch {
                return data
            }
        }, // UPPERCASE
        TE02X: (data) => {
            try {
                // lowercase
                return data.toLowerCase()
            } catch {
                return data
            }
        }, 
        TE03S: (data) => {
            try {
                // Sentences case
                const lower = data.toLowerCase()
                return data.charAt(0).toUpperCase() + lower.slice(1)
            } catch {
                return data
            }
        }, 
        TE03C: (data) => {
            try {
                // Capitalize Case
                return data
                  .toLowerCase()
                  .split(' ')
                  .map(element => element.charAt(0).toUpperCase() + element.slice(1))
                  .join(' ');
            } catch {
                return data                
            }
        }, 
        NU01D: (data) => {
            try {
                return helpers.roundNumberType(data, true, true, false)
            } catch {
                return data
            }
        },
        NU01C: (data) => {
            try {
                return helpers.roundNumberType(data, true, false, false)
            } catch {
                return data
            }
        },
        NU02D: (data) => {
            try {
                return helpers.roundNumberType(data, false, true, false)
            } catch {
                return data
            }
        },
        NU02C: (data) => {
            try {
                return helpers.roundNumberType(data, false, false, false)
            } catch {
                return data
            }
        },
        NU03D: (data) => {
            try {
                return helpers.roundNumberType(data, true, true, true)
            } catch {
                return data
            }
        },
        NU03C: (data) => {
            try {
                return helpers.roundNumberType(data, true, false, true)
            } catch {
                return data
            }
        },
        NU04D: (data) => {
            try {
                return helpers.roundNumberType(data, false, true, true)
            } catch {
                return data
            }
        },
        NU04C: (data) => {
            try {
                return helpers.roundNumberType(data, false, false, true)
            } catch {
                return data
            }
        },
    },
}
